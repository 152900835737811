import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import Opportunities from "../components/opportunities"
import Carusel from "../components/carusel"
import Image from "../components/Image"
import FramePlaceholder from "../components/frame-placeholder"
import TwoColsContainer from "../components/two-cols-container"
import TextBox from "../components/text-box"
import { HeroHome } from "../components/hero"
import ArrowDown from "../images/arrow-down.svg"
import ReadMore from "../images/read-more.svg"
import Hs from "../images/h-sperator.svg"
import {OpenModalButton} from "../components/modal/video"

const IndexPage = ({ data, ...props }) => {

  const section1BG = data.section1BG
  const section2BG = data.section2BG
  const { section3 } = data.homepageJson

  const seo = getMetatags(data.nodeHomepage.metatag/* , nodeOverview.relationships.OGImage?.localFile?.publicURL */)

  const { hero2Title, hero2Subtitle, tabsTitle, opportunitiesTitle/* , link */ } = data.nodeHomepage
  const { hero, logos, vision, what, tabs, hover, opportunities } = data.nodeHomepage.relationships

  //console.log(data.nodeHomepage.title)
  //console.log(seo)
  return (
    <Layout>
      <Seo {...seo} />
      <HeroHome className="mt-[90px] lg:mt-[126px]   " {...hero} />
      <Logos items={logos} />
      <Arrow />
      <Hero2 title={hero2Title} subtitle={hero2Subtitle} />
      <Section1 bgImage={section1BG} {...vision} />
      <Section2 bgImage={section2BG}   {...what} />
      <Section3  {...section3} tabs={tabs} title={tabsTitle} hover={hover} />
      <Section4 title={opportunitiesTitle} items={opportunities} />

      { /* width !== 0 && */ <React.Suspense fallback="loading..."></React.Suspense>}

    </Layout >
  )
}

export default IndexPage


const Logos = ({ items, ...props }) => {

  return (
    <section className="container max-w-screen-lg	 flex flex-row justify-around items-center py-8 space-x-8 space-x-reverse">
      {items.map((item, index) => {
        const isExternal = item.link.includes('https://')

        const image = <img src={item.relationships.image.localFile.publicURL} alt={item.image.alt} className="grayscale hover:grayscale-0" />
        const e = isExternal ? <a href={item.link} target="__blank">{image}</a> : <Link to={item.link}>{image}</Link>
        return (
          <div className="" key={index}>{e}</div>
        )
      })}
    </section >
  )
}

const Arrow = () => {

  return (
    <section className="mb-4 hidden lg:block">
      <div className="container">
        <img src={ArrowDown} alt="" className="mx-auto" />
      </div>
    </section>
  )
}


const Hero2 = ({ title, subtitle }) => {
  return (
    <section className="container  py-8 lg:py-12 w-full">
      <div className="text-[26px] lg:text-[50px] lg:leading-[55px] text-center font-bold">
        <h3 className="text-blue">{title}</h3>
        <h4>{subtitle}</h4>
      </div>
    </section>
  )
}

const Section1 = ({ title, description, images, relationships, bgImage, ...props }) => {
  //console.log(description)
  return (
    <TwoColsContainer
      className="mb-12 lg:mb-32 bg-right-top "
      colsClassName=""
      col1ClassName="lg:w-5/12 mb-[24px] z-20"
      col2ClassName="lg:w-7/12 relative z-10"
      bgImage={bgImage}
      col1={
        <FramePlaceholder flip>
          <TextBox
            title={title}
            titleClassName="font-bold"
            className=" p-4 lg:p-20 mx-auto shadow-lg lg:w-[653px] bg-white  text-justify"
            html={description.processed}
          />
        </FramePlaceholder>
      }
      col2={<Carusel items={relationships.images.map((e, i) => (
        <Image image={e} alt={images[i].alt} key={i} />
      ))} />}

    />
  )
}

const Section2 = ({ title, description, images, relationships, link, bgImage, ...props }) => {

  //console.log(link)
  return (
    <TwoColsContainer
      className="mb-12 lg:mb-32 bg-left-top"
      colsClassName=""
      col1ClassName="lg:w-7/12 z-10 relative"
      col2ClassName="lg:w-5/12 z-20"
      col1={<Image image={relationships.images[0]} alt={images[0].alt} />}
      col2={
        <FramePlaceholder className=" relative lg:right-[-36px] lg:bottom-[-18px]">
          <TextBox
            title={title}
            titleClassName="font-bold"
            className=" p-4 lg:p-20 mx-auto shadow-lg lg:w-auto bg-white text-justify"
            html={description.processed}
          >
            {link &&
              <div className="text-left">
                <Link to={link.uri} className="text-yellow2 text-lg inline" target="_blank">{link.title} <img src={ReadMore} alt="read more" aria-hidden="true" className="inline" /></Link>
              </div>
            }
          </TextBox>

        </FramePlaceholder>
      }
      bgImage={bgImage}
    />
  )
}


const Section3 = ({ title, tabs, legend, button, hover, ...props }) => {

  const [tabId, setTabId] = useState('tab0')
  const tabCount = tabs.length


  const changeTab = (e) => {
    setTabId(e.target.id)
  }

  const handlekeyPress = (e) => {

    //console.log(e.keyCode)
    const index = parseInt(tabId.replace('tab' , '') )
    
    let newIndex = index 
    switch (e.keyCode) {
      case 37:
        newIndex++
        if( newIndex > tabCount -1 ) newIndex = 0
        break;
    case 39:
        newIndex--
        if( newIndex < 0 ) newIndex = tabCount -1
        break;
        
      default:
        break;
    }

    setTabId('tab' + newIndex)
  }


  return (
    <section className=" bg-light-blue">
      <div className="container  py-12 rtl space-y-8">
        <h2 className="text-[34px] text-blue font-bold "><img src={Hs} alt="" className="inline ml-1.5 " />{title}</h2>
        <div className="flex flex-col-reverse lg:flex-row justify-between ">

          <div className="" >
            <div
              onKeyDown={handlekeyPress}
              role="tablist" 
              aria-labelledby="tablist-1" 
              className="flex space-x-1 space-x-reverse"
            >
            {tabs.map((item, index) => (
              <button
                key={index}
                id={"tab" + index}
                className={"p-3 hover:border-navy border " + ("tab" + index === tabId ? ' bg-navy text-white border-navy font-bold ' : ' bg-white ')}
                onClick={changeTab}
                onFocus={changeTab}
                role="tab"
                aria-selected={"tab" + index === tabId}
                aria-controls={`tabpanel-${index+1}`}
                //data={index}
                
                >
                {item.title}
                
              </button>
            ))}
            </div>
          </div>
          <div className="mb-4 lg:mb-auto">
            <OpenModalButton id="openModal2" />
          </div>
        </div>
        {/* <div>{tabId}</div> */}
        <div className="flex flex-col-reverse	 lg:flex-row ">
          <div className="lg:w-1/3 flex flex-col">
            <div className="border-b border-t border-yellow  w-[330px] my-4  py-2 flex flex-row justify-between items-center">
              <div className="text-2xl font-bold">{legend.title}</div>
              {/* <div><img src={ArrowVertical} alt="arrow" /></div> */}
            </div>
            <div className="space-y-5 my-4 flex-grow">
              {legend.items.map((item, index) => (
                <div className="flex flex-row space-x-2 space-x-reverse" key={index}>
                  <div className="w-8 h-8 " style={{ backgroundColor: `${item.color}` }}></div>
                  <div className="text-lg">{item.label} </div>
                  <div className="text-sm max-w-[300px]">{item.description} </div>
                </div>
              ))}
            </div>
            <div className="border px-6 py-2 bg-navy text-white w-fit text-lg mt-4 hover:bg-white hover:border hover:text-black border-blue">
              <Link to={button.url}>{button.label}</Link>
            </div>
          </div>
          <div className="lg:w-2/3">
            <div>
              {tabs.map((item, index) => (
                <div key={index} role="tabpanel" aria-labelledby={`tab${index}`}  className={" relative " + ("tab" + index === tabId ? ' block ' : ' hidden ')}>
                  {index !== 1 ? <CardSimple item={item} /> : <CardWithHover item={item} />}
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    </section >
  )
}


const CardSimple = ({ item }) => {

  return (
    <Image image={item.relationships.image} alt={item.image.alt} className="" />
  )
}

const CardWithHover = ({ item }) => {

  const [mitham, setMitham] = useState('')


  const compounds = [
    { name: "A", top: "20", right: "14" },
    { name: "B", top: "38", right: "8" },
    { name: "C", top: "48", right: "26" },
    { name: "D", top: "66", right: "56" },
    { name: "E", top: "78", right: "83" },
    { name: "F", top: "58", right: "78" },
    { name: "G", top: "32", right: "60" },
    { name: "H", top: "14", right: "40" },
    { name: "I", top: "23", right: "24" },
    { name: "J", top: "30", right: "32" },
    { name: "K", top: "43", right: "37" },
    { name: "L", top: "33", right: "47" },
    { name: "M", top: "50", right: "54" },
    { name: "N", top: "61", right: "65" },

  ]


  const Buttons = <>{compounds.map((e, i) => (
    <div
      className=" absolute z-20 "
      style={{ top: `${e.top}%`, right: `${e.right}%` }}
      key={i}
    >
      <button
        className="bg-white text-yellow border rounded-full w-6 h-6 lg:w-10 lg:h-10 text-xs lg:text-lg lg:font-bold "
        onClick={() => setMitham(e.name)}
        onMouseEnter={() => setMitham(e.name)}
        onMouseLeave={() => setMitham('')}
      >{e.name}</button>
    </div>
  ))}</>


  const hoverMaps = <>{compounds.map((e, i) => (
    <div key={i} className={" absolute top-0 bottom-0 right-0 left-0 z-10" + (e.name === mitham ? ' block' : ' hidden')}>
      <img src={`/hover/${e.name}-hover.png`} alt={e.name} />
    </div>
  ))}</>

  return (
    <>
      <Image image={item.relationships.image} alt={item.image.alt} className="" />
      {Buttons}
      {hoverMaps}
    </>
  )
}



const Section4 = ({ title, items, ...props }) => {

  return (
    <section className="lg:py-32 py-12">
      <div className="space-y-12">
        <h2 className="text-center text-blue text-[26px] lg:text-[34px] font-bold"><img src={Hs} alt="" className="inline ml-1.5 " />{title}</h2>
        <Opportunities items={items} />
      </div>
    </section>
  )
}



//export const Head = () => <title>Home Page</title>

export const query = graphql`
  query Index2Query {
    nodeHomepage(id:{eq:"dbb76380-a814-52c8-b8e7-50de6a10d0b1"}) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      link: field_link {
        title
        uri
      }
      hero2Subtitle: field_title
      hero2Title: field_title1
      tabsTitle: field_title3
      opportunitiesTitle: field_title2
      relationships {
        hero: field_hero {
          ...Hero
        }
        logos: field_paragraph_repeater {
          ...ImageLink
        }
        vision: field_paragraph {
          ...TitleTextImages
        }
        what: field_paragraph1 {
          ...TitleTextImages
        }
        tabs: field_paragraph_repeater1 {
          ...TitleImage
        }
        hover: field_paragraph_repeater3 {
          ...TitleImage
        }
        opportunities: field_paragraph_repeater2 {
          ...TitleTextImages
        }
      }
    }
    backgrounds: allFile(filter: {sourceInstanceName: {eq: "backgrounds"}}) {
      nodes {
        base
        publicURL
      }
    }
    section1BG: file(sourceInstanceName: {eq: "backgrounds"}, base: {eq: "section1-bg.svg"}) {
      base
      publicURL
    }
    section2BG: file(sourceInstanceName: {eq: "backgrounds"}, base: {eq: "section2-bg.svg"}) {
      base
      publicURL
    }
    homepageJson {
      id
      section3 {
        button {
          label
          url
        }
        legend {
          items {
            description
            id
            label
            color
          }
          title
        }
      }
    }
  }
`
